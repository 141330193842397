<script lang="ts">import { BaseParticle } from "components/cards/particles";
import "../Text";
import { afterUpdate, onMount } from "svelte";
import { resolveUrl } from "js/url";
import "./spec";
import { getTranslator } from "components/cards/utilities";
// props
export let container = undefined;
export let textDefaults = undefined;
export let html = "";
export let translations = [];
export let fillWidth = undefined;
export let alignment = undefined;
// inputs
export let _block = false;
let wrapperSpan;
$: xlator = getTranslator(translations, { html });
$: styles = {
    color: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.color,
    family: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.family,
    size: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.size,
    weight: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.weight,
    style: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.style,
};
afterUpdate(() => {
    if (!wrapperSpan) {
        return;
    }
    const aTags = wrapperSpan.querySelectorAll('a');
    for (const aTag of aTags) {
        const href = aTag.getAttribute('href');
        const resolved = resolveUrl(href);
        if (resolved.error) {
            console.warn('Unable to resolve href', href);
            continue;
        }
        aTag.setAttribute('href', resolved.href);
        if (resolved.external) {
            aTag.classList.add('external');
            aTag.target = '_blank';
        }
        else {
            aTag.classList.remove('external');
            aTag.target = '_self';
        }
    }
    const ytIframes = wrapperSpan.querySelectorAll('iframe[title="YouTube video player"]');
    for (const ifTag of ytIframes) {
        if (ifTag.parentElement === wrapperSpan) {
            const width = ifTag.getAttribute('width');
            const height = ifTag.getAttribute('height');
            ifTag.removeAttribute('width');
            ifTag.removeAttribute('height');
            ifTag.style.width = '100%';
            ifTag.style.aspectRatio = `${width}/${height}`;
            wrapperSpan.style.display = 'block';
            wrapperSpan.style.width = '100%';
        }
    }
    const twttr = wrapperSpan.querySelector('blockquote.twitter-tweet');
    if (twttr) {
        setTimeout(() => {
            var _a;
            try {
                (_a = window.twttr) === null || _a === void 0 ? void 0 : _a.widgets.load();
            }
            catch (_b) {
                console.log('RichTextParticle: unable to load Twitter widgets');
            }
        }, 0);
    }
    const instgrm = wrapperSpan.querySelector('blockquote.instagram-media');
    if (instgrm) {
        setTimeout(() => {
            var _a;
            try {
                (_a = window.instgrm) === null || _a === void 0 ? void 0 : _a.Embeds.process();
            }
            catch (_b) {
                console.log('RichTextParticle: unable to load Instagram widgets');
            }
        }, 0);
    }
    const cortexNode = wrapperSpan.querySelector('cortex-live-blog');
    if (cortexNode) {
        const cortexScript = document.getElementById('cortex-script');
        if (!cortexScript) {
            const newScript = document.createElement('script');
            newScript.setAttribute('id', 'cortex-script');
            newScript.setAttribute('src', 'https://live-blog.cortextech.io/index.js');
            newScript.setAttribute('type', 'text/javascript');
            document.body.appendChild(newScript);
        }
    }
});
onMount(() => {
    if (!wrapperSpan) {
        return;
    }
    const obs = new ResizeObserver((ents, obs) => {
        const ifTags = wrapperSpan.querySelectorAll('iframe[src*="instagram.com"], iframe[src*="facebook.com"]');
        for (const ifTag of ifTags) {
            if (ifTag.classList.contains('yc-responsive-embed') || !ifTag.clientHeight || !ifTag.clientWidth || wrapperSpan.clientWidth > 500) {
                continue;
            }
            ifTag.classList.add('yc-responsive-embed');
            window.requestAnimationFrame(() => {
                const ratio = (ifTag.clientHeight / ifTag.clientWidth) * 100.0;
                const wrap = document.createElement('div');
                wrap.style.position = 'relative';
                wrap.style.paddingTop = `calc(${ratio}% + 15%)`;
                wrap.style.width = '100%';
                wrap.classList.add('yc-responsive-embed-wrapper');
                ifTag.style.position = 'absolute';
                ifTag.style.width = '100%';
                ifTag.style.height = '100%';
                ifTag.style.top = '0';
                ifTag.parentElement.insertBefore(wrap, ifTag);
                wrap.appendChild(ifTag);
            });
        }
    });
    obs.observe(wrapperSpan);
});
</script>

<style>
  span {
    color: var(--rich-text-particle-color, inherit);
    font-family: var(--rich-text-particle-family, inherit);
    font-size: var(--rich-text-particle-size, inherit);
    font-weight: var(--rich-text-particle-weight, inherit);
    font-style: var(--rich-text-particle-style, inherit);
  }

  span.rtp-display-block {
    display: block;
    max-width: 100vw;
    overflow-x: auto;
  }

  span.rtp-display-block.rtp-with-fill-width {
    width: 100%;
  }

  span.rtp-display-block.rtp-with-fill-width.rtp-with-alignment {
    display: flex;
    flex-direction: row;
    justify-content: var(--rich-text-particle-alignment, unset);
  }</style>

<BaseParticle _name="RichTextParticle" _inline={!_block} _cssVariablePrefix="rich-text-particle" {...container} {...styles} {alignment} >
  <span bind:this={wrapperSpan}
    class:rtp-display-block={_block}
    class:rtp-with-fill-width={fillWidth}
    class:rtp-with-alignment={alignment}>
    {@html xlator({ html })}
  </span>
</BaseParticle>
